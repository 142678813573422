<template>
    <setting-block-layout
        :loading="loading"
        @save="onSave"
        @cancel="onCancel"
    >
        <template #body>
            <div>
                <div class="setting_writer">
                    <form-select-writers
                        v-model="preferred_writers"
                        name="preferred_writers"
                        label="Preferred writers"
                        :writers="listWriters"
                        :styles="true"
                        :disabled_items="blocked_writers"
                        :icon="'Preferred'"
                        :title="'Preferred writers'"
                        :writers-array-length="5"
                        :loading="loadingList"
                        @loadMore="loadMoreWriters"
                        @searchWriters="onSearchWriters"
                    />
                </div>
                <div class="setting_writer">
                    <form-select-writers
                        v-model="blocked_writers"
                        name="blocked_writers"
                        label="Blocked writers"
                        :writers="listWriters"
                        :styles="false"
                        :disabled_items="preferred_writers"
                        :icon="'Blocked'"
                        :title="'Blocked writers'"
                        :writers-array-length="5"
                        :loading="loadingList"
                        @loadMore="loadMoreWriters"
                        @searchWriters="onSearchWriters"
                    />
                </div>
            </div>
        </template>
    </setting-block-layout>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { createNamespacedHelpers } from 'vuex'
import { eventBus } from '@/helpers/event-bus/'
import SettingBlockLayout from '@/components/account-settings/SettingBlockLayout';
import formValidationMixin from '@/mixins/formValidationMixin'

import {
    mapClientFields
} from '@/store/modules/client';

import {
    GET_LIST_WRITERS
} from '@/store/modules/config/action-types'

import {
    ACTION_CLIENT_UPDATE_WRITERS
} from '@/store/modules/client/action-types';

const { mapActions: mapConfigActions } = createNamespacedHelpers('config')

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client');

export default {
    name: 'ProfileWriters',
    components: {
        SettingBlockLayout
    },
    metaInfo: {
        title: 'Writer Preferences'
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    data() {
        return {
            listWriters: [],
            next_page_url: null,
            searchOptions: {
                page: 1,
                per_page: 10,
                mode: 'list',
                search_for: '',
                search_by: ''
            },
            loading: false,
            loadingList: false
        }
    },
    computed: {
        ...mapClientFields([
            'client_data.preferred_writers',
            'client_data.blocked_writers'
        ])
    },
    async created() {
        // this.listWriters = await this[GET_LIST_WRITERS]();
        await this.getList()
    },
    methods: {
        ...mapConfigActions([
            GET_LIST_WRITERS
        ]),
        ...mapClientActions({
            updateWriters: ACTION_CLIENT_UPDATE_WRITERS
        }),
        async onSave() {
            try {
                this.loading = true
                await this.updateWriters({
                    preferred_writers: this.preferred_writers.map((a) => +a.id),
                    blocked_writers: this.blocked_writers.map((a) => +a.id)
                })
                eventBus.$emit('showSnackBar', 'Your writers list has been changed', 'success');
                this.$router.push({ name: 'profile' })
            } catch (err) {
                eventBus.$emit('showSnackBar', err, 'error');
            } finally {
                this.loading = false
            }
        },
        onCancel() {
            this.$router.push({ name: 'profile' })
        },
        async getList() {
            try {
                this.loadingList = true
                const response = await this.getData()
                this.next_page_url = response.next_page_url
                this.listWriters = [...this.listWriters, ...response.data]
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            } finally {
                this.loadingList = false
            }
        },
        async getData() {
            const data = await this[GET_LIST_WRITERS]({
                ...this.searchOptions
            })
            return data
        },
        async loadMoreWriters(scrollChecker) {
            if (scrollChecker && (!this.loadingList && !!this.next_page_url)) {
                this.searchOptions.page += 1
                await this.getList()
            }
        },
        async onSearchWriters(payload) {
            this.searchOptions = {
                ...this.searchOptions,
                page: 1,
                search_by: 'sw_id',
                search_for: payload
            }
            this.listWriters = []
            await this.getList()
        }
    }
}
</script>

<style lang="scss">
    .setting_writer{
        margin: 30px 0;
        .report .tool {
            transform: translateX(15px);
        }
        &:first-child{
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
</style>
